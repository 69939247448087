import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export enum DialogNames {
  CONFIRM = 'ConfirmDialog',
  FILTER = 'FilterDialog',
  FIELD = 'FieldDialog',
  ADD_FIELD = 'AddFieldDialog',
  POSITION = 'PositionDialog',
  LOGIN = 'LoginDialog',
  HOBBY_GROUP = 'HobbyGroupDialog'
}

@Injectable({
  providedIn: 'root'
})
export class LazyDialogService {
  constructor(private dialog: MatDialog) {}

  async openLazyLoadedDialog<T>(dialogName: DialogNames, config: MatDialogConfig): Promise<MatDialogRef<T>> {
    let dialogComponent;

    // Use a switch statement to handle different dialog names
    switch (dialogName) {
      case 'ConfirmDialog':
        dialogComponent = (await import(`./../modals/confirmation-modal`)).ConfirmationModalComponent;
        break;
      case 'FilterDialog':
        dialogComponent = (await import(`./../modals/filter-modal`)).FilterModalComponent;
        break;
      case 'FieldDialog':
        dialogComponent = (await import(`./../modals/field-modal`)).FieldModalComponent;
        break;
      case 'AddFieldDialog':
        dialogComponent = (await import(`./../modals/add-field-modal`)).AddFieldModalComponent;
        break;
      case 'PositionDialog':
        dialogComponent = (await import(`./../modals/position-modal`)).PositionModalComponent;
        break;
      case 'LoginDialog':
        dialogComponent = (await import(`./../modals/login-modal`)).LoginModalComponent;
        break;
      case 'HobbyGroupDialog':
        dialogComponent = (await import(`./../modals/group-modal`)).GroupModalComponent;
        break;
      // ... other cases for different dialogs
      default:
        throw new Error(`Unknown dialog name: ${dialogName}`);
    }

    // Ensure that the dialog component is a ComponentType
    dialogComponent = dialogComponent as ComponentType<T>;
    return this.dialog.open(dialogComponent, config);
  }
}
